// author:马云阳；description:众惠-tablie组件
<template>
  <div class="tables_">
    <div class="list_cont">
      <div class="list_title">
        <div class="left_ti">
          {{titles}}
        </div>
        <div v-if="showrightBtn" class="right_btn">
          <a @click="toDividentFit()">设置分红方式</a> <span class="iconfont" style="font-size:10px;">&#xe663;</span>
        </div>
      </div>
      <div v-if="tableList&&tableList.length>0" class="table_">
        <!-- v-for="(obj,i) in trs" :key="i"  -->
        <div class="tr_ti">
          <div :class="operation==1?'ti_1':operation==2?'ti_1ac':operation==3?'ti_1ac':operation==4?'ti_4_1ac_':''">
            {{trs.title1}}</div>
          <div :class="operation==1?'ti_2':operation==2?'ti_2ac':operation==3?'ti_2ac':operation==4?'ti_4_2ac':''">
            {{trs.title2}}</div>
          <div :class="operation==1?'ti_2':operation==2?'ti_3ac':operation==3?'ti_3ac':operation==4?'ti_4_3ac':''">
            {{trs.title3}}</div>
          <!-- <div v-if="operation==1" class="ti_2">{{trs.title4}}</div> -->
          <div v-if="operation==1" class="ti_2">{{trs.title5}}</div>
          <div v-if="operation==1" class="ti_6">{{trs.title6}}</div>
          <!-- <div :class="operation==1?'ti_4':operation==2?'ti_4ac':operation==3?'ti_4ac':operation==4?'ti_4_4ac':''">
            {{trs.title4}}</div> -->
          <!-- <div></div> -->
          <div v-if="operation!=1&&operation!=4"
            :class="operation==1?'ti_5':operation==2?'ti_5ac':operation==3?'ti_3_5ac':operation==4?'ti_4_5ac':''">
            {{trs.title5}}</div>
        </div>
        <!--  -->
        <div v-for="(item,j) in tableList" :key="j" class="td">
          <div @click.stop="toDetail(item)" @mouseenter='move($event,j)' @mouseleave="moveout($event)"
            :class="operation==1?'td_s':operation==2?'td_sac colors':operation==3?'td_sac':operation==4?'ti_4_1ac_':'td_s'">
            <div class="td_1"><p style="color:#8691A8;text-indent:2em">{{item.fundCode}}</p><p style="text-indent:2em;">{{item.fundName}}</p> </div>
            <div v-if="showindexs==j&&showmodels&&showTips" class="show_models">{{item.fundName}}
            </div>
          </div>
          <div
            :class="[operation==1?'td_2':operation==2?'td_2ac':operation==3?'td_2ac':operation==4?'ti_4_2ac':'','',operation==1&&item.todayIncome>=0?'profitac':operation==1&&item.todayIncome<0?'profit':'']">
            {{operation==1?item.todayIncome+'元':operation==3||operation==4?item.fundShare+'元':operation==2?item.enddate||'--':''}}</div>
          <div :class="operation==1?'td_2':operation==2?'td_3ac':operation==3?'td_2ac':operation==4?'ti_4_3ac':''">
            {{operation==1||operation==4?String(item.accumIncome+'元')||'--':operation==3?String(item.holdingIncome+'元')||'--':operation==2?item.newestValue||'--':''}}</div>
          <!-- <div v-if="item.totalRate>=0"
            :class="[operation==1?'td_4':operation==2?'td_4ac':operation==3?'td_2ac':operation==4?'ti_4_4ac':'',item.totalRate>=0?'profitac':'profit']">
            {{operation==4||operation==3||operation==1?String('+'+item.totalRate+'%')||'--':''}}</div>
            <div v-if="operation==2"
            :class="[operation==1?'td_4':operation==2?'td_4ac':operation==3?'td_2ac':operation==4?'ti_4_4ac':'',item.changeDay>=0?'profitac':'profit']">
            <span>{{Number(item.changeDay)>=0?'+':"-"}}</span>{{item.changeDay}}%</div> -->

          <!-- <div v-if="item.totalRate<0"
            :class="[operation==1?'td_4':operation==2?'td_4ac':operation==3?'td_2ac':operation==4?'ti_4_4ac':'',item.totalRate>=0?'profitac':'profit']">
            {{operation==4||operation==3||operation==1?String(item.totalRate+'%')||'--':''}}</div> -->
          <!-- <div v-if="operation==1" class="td_2">{{String(item.fundShare+'元')||'--'}}</div> -->
          <div v-if="operation==1" class="td_2">{{String(item.fundShare+'元')||'--'}}</div>
          
          <div v-if="operation==1" class="td_5">
            <a @click="purchase(item)" :class="['r_btn', {'disable stop-buy':item.buyStatus === '0'}]">买入</a>
            <a @click="sellout(item)" class="c_btn">卖出</a>
            <a @click="changes(item)" :class="['l_btn', {'disable stop-buy':item.buyStatus === '0'}]">转换</a>
          </div>
          <div v-if="operation==2" class="td_5ac">
            <a @click="changesinto(item)" :class="['btn_s', {'disable stop-buy':item.buyStatus === '0'}]">
              转入
            </a>
          </div>
          <div v-if="operation==3" class="td_5ac">
            <a @click="sellout(item)" class="btn_s">
              卖出
            </a>
          </div>
        </div>
      </div>
      <div v-else class="null_tab">
        <img src="@/static/img/my/null_bg.png" alt="">
        <div class="null_txt">暂无相关交易记录</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TablieList",
  props: {
    // 表头数据
    trs: {
      type: Object,
      defalut: () => {
        return {};
      }
    },
    // tab数据
    tableList: {
      type: Array,
      defalut: () => {
        return [];
      }
    },
    // 是否显示标题右边按钮
    showrightBtn: {
      type: Boolean,
      default: false
    },
    // 标题
    titles: {
      type: String,
      default: ""
    },
    // 不同页面样式控制
    operation: {
      type: Number,
      default: 1
    },
    /** 是否显示提示 */
    showTips: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tds: [],
      showindexs: null,
      showmodels: false
    };
  },
  methods: {
    toDetail(obj){
      this.$emit('toDetail',obj)
    },
    move(e, j) {
      this.showmodels = true;
      this.showindexs = j;
    },
    moveout() {
      this.showmodels = false;
      this.showindexs = null;
    },
    // 买入
    purchase(obj) {
      if(obj.buyStatus === '0') {
        return;
      }
      this.$emit("purchase", obj);
    },
    // 卖出
    sellout(obj) {
      this.$emit("sellout", obj);
    },
    // 转换
    changes(obj) {
      if(obj.buyStatus === '0') {
        return;
      }
      this.$emit("changes", obj);
    },
    // 转入
    changesinto(obj) {
      if(obj.buyStatus === '0') {
        return;
      }
      this.$emit("changesinto", obj);
    },
    toDividentFit() {
      this.$router.push({ name: "DividentFit" });
    }
  }
};
</script>
<style lang="less" scoped>
.onlnes {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.colors{
  color: #25293D;
}
.profitac {
  color: #e45247 !important;
}

.profit {
  color: #01b834 !important;
}

.tables_ {
  .list_cont {
    .list_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 103px;
      border-top: 1px solid #edf1f7;

      .left_ti {
        font-size: 18px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
        font-weight: 700;
        text-align: LEFT;
        color: #1f1f1f;
      }

      .right_btn {
        font-size: 16px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
        font-weight: 400;
        color: #bdc0cb;
      }
    }

    .table_ {
      .tr_ti {
        display: flex;
        align-items: center;

        div {
          height: 59px;
          font-size: 12px;
          color: #1f1f1f;
          background: #f3f4f6;
          line-height: 60px;
          border-top: 1px solid #f3f4f6;
          border-left: 1px solid #ffffff;
          text-indent: 2em;

          &:first-child {
            border-left: 1px solid #f3f4f6;
          }

          &:last-child {
            border-right: 1px solid #f3f4f6;
          }
        }

        .ti_1 {
          width: 249px;
        }

        .ti_1ac {
          width: 219px;
        }

        .ti_4_1ac_ {
          width: 299px;
          position: relative;

          .td_1 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 12px;
            }
          }

          .show_models {
            width: 200px;
            min-height: 30px !important;
            height: 0px;
            padding: 4px;
            text-indent: 0em !important;
            background: #fff;
            border: 1px solid #f1f1f1;
            position: absolute;
            left: 120px;
            top: 0px;
            z-index: 999;
            line-height: 20px !important;
            box-shadow: 0px 2px 4px 0px #edf1f7;
          }
        }

        .ti_5 {
          width: 324px;
        }

        .ti_5ac {
          width: 326px;
        }

        .ti_3_5ac {
          width: 324px;
          text-align: center;
          text-indent: 0em;
        }

        .ti_3ac,
        .ti_4ac,
        .ti_2ac {
          width: 149px;
        }

        .ti_4_2ac {
          width: 179px;
        }

        .ti_4_3ac {
          width: 365px;
        }

        .ti_4_4ac {
          width: 166px;
        }

        .ti_2 {
          width: 89px;
        }
        .ti_6{
          width: 325px;
        }
        .ti_3 {
          width: 149px;
        }

        .ti_4 {
          width: 99px;
        }
      }

      .td {
        display: flex;
        align-items: center;

        &:last-child {
          border-bottom: 1px solid #f3f4f6;
        }

        div {
          height: 59px;
          font-size: 12px;
          color: #1f1f1f;
          line-height: 60px;
          border-top: 1px solid #f3f4f6;
          border-left: 1px solid #f3f4f6;
          text-indent: 2em;

          &:first-child {
            border-left: 1px solid #f3f4f6;
          }

          &:last-child {
            border-right: 1px solid #f3f4f6;
          }
        }

        .td_s {
          width: 249px;
          position: relative;

          .td_1 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 12px;
              
            }
          }

          .show_models {
            width: 200px;
            min-height: 30px !important;
            height: 0px;
            padding: 4px;
            text-indent: 0em !important;
            background: #fff;
            border: 1px solid #f1f1f1;
            position: absolute;
            left: 120px;
            top: 0px;
            z-index: 999;
            line-height: 20px !important;
            box-shadow: 0px 2px 4px 0px #edf1f7;
          }
        }

        .td_sac {
          width: 219px;
          position: relative;

          .td_1 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 12px;
              
            }
          }

          .show_models {
            width: 200px;
            min-height: 30px !important;
            height: 0px;
            padding: 4px;
            text-indent: 0em !important;
            background: #fff;
            border: 1px solid #f1f1f1;
            position: absolute;
            left: 120px;
            top: 0px;
            z-index: 999;
            line-height: 20px !important;
            box-shadow: 0px 2px 4px 0px #edf1f7;
          }
        }

        .td_5 {
          width: 325px;
          display: flex;
          align-items: center;
          flex-direction: row-reverse;

          a {
            display: inline-block;
            width: 54px;
            height: 26px;
            line-height: 26px;
            border: 1px solid #ccc;
            text-align: center;
            text-indent: 0em;
            border-radius: 2px;
            margin-right: 10px;
            font-size: 12px;

            &:first-child {
              margin-right: 19px;
            }
          }

          .l_btn {
            border: 1px solid #e0e2e8;
            color: #25293d;
          }

          .c_btn {
            border: 1px solid #fff5ea;
            color: #ce9b63;
            background: #fff5ea;
          }

          .r_btn {
            border: 1px solid #eabf96;
            color: #f3f4f6;
            background: linear-gradient(298deg, #e0ab6f 0%, #eabf96 100%);
          }
        }

        .td_5ac {
          width: 324px;
          display: flex;
          align-items: center;
          justify-content: center;

          .btn_s {
            display: inline-block;
            background: linear-gradient(287deg, #e0ab6f 0%, #eabf96 100%);
            border-radius: 2px;
            width: 98px;
            height: 28px;
            text-align: center;
            line-height: 28px;
            font-size: 12px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            color: #f3f4f6;
            text-indent: 0em;
          }
        }

        .td_3ac,
        .td_4ac,
        .td_2ac {
          width: 149px;
          color: #25293d;
        }
        .colors {
          color: #25293d !important;
        }
        .ti_4_2ac {
          width: 179px;
        }
        .ti_4_1ac_ {
          width: 299px;
          position: relative;

          .td_1 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 12px;
              
            }
          }

          .show_models {
            width: 200px;
            min-height: 30px !important;
            height: 0px;
            padding: 4px;
            text-indent: 0em !important;
            background: #fff;
            border: 1px solid #f1f1f1;
            position: absolute;
            left: 120px;
            top: 0px;
            z-index: 999;
            line-height: 20px !important;
            box-shadow: 0px 2px 4px 0px #edf1f7;
          }
        }
        .ti_4_3ac {
          width: 365px;
        }

        .ti_4_4ac {
          width: 166px;
        }
        .td_2 {
          width: 89px;
          text-align: center!important;
          text-indent: 0em;
        }

        .td_3 {
          width: 149px;
        }

        .td_4 {
          width: 99px;
        }
      }
    }
    .null_tab {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      img {
        width: 266px;
        height: 266px;
        margin-bottom: 20px;
      }
      .null_txt {
        font-size: 16px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #ce9b63;
      }
    }
  }
}
</style>;