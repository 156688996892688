<template>
  <div>
    <section class="publicfund ">
      <div class="publicfund_cont">
        <div class="title_">惠金宝</div>
        <div class="price_banner">
          <span class="ti_price">惠金宝</span>
          <span class="ti_price_"
            >现金宝是众惠基金推出的一款投资优选货币基金的理财工具</span
          >
          <div class="price_">
            <div class="price_num">￥{{fundData.fundShare||'--'}}</div>
            <div class="menu">
              <router-link :to="{name:'GetMoney'}" class="getMoney">提现</router-link>
              <router-link :to="{name:'RechargeS1'}" class="recharge">充值</router-link>
            </div>
          </div>
        </div>
        <div class="tag_cont">
          <div class="tag_">
            <div class="tag_img">
              <span class="iconfont t">&#xe697;</span>
            </div>
            <div class="tag_ti">持有收益<span>(元)</span></div>
            <div class="tag_num">{{fundData.holdingIncome||'--'}}</div>
          </div>
          <div class="tag_">
            <div class="tag_img">
              <span class="iconfont t">&#xe68c;</span>
            </div>
            <div class="tag_ti">累计收益<span>(元)</span></div>
            <div class="tag_num"> {{fundData.accumIncome||'--'}}</div>
          </div>
          <!-- <div class="tag_">
            <div class="tag_img">
              <span class="iconfont t">&#xe6a2;</span>
            </div>
            <div class="tag_ti">预计最高7日年化</div>
             <div :class="day7<0?'profit':'profitac'" class="tag_num ">
               <span>+{{day7||"--"}}%</span>            
             </div>
          </div> -->
        </div>
        <!-- <div class="tableline"></div> -->
        <div class="tables_cont">
          <TablieList
            :titles="'收益明细'"
            :trs="trs"
            :tableList="lists"
            :showrightBtn="false"
            :operation="4"
            :showTips="false"
            @toDetail="toDetail"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import TablieList from "@/components/TablieList";
import { findTotalBond,highest7 } from "@/api/publicfund";

export default {
  name: "Hui",
  components: { TablieList },
  data() {
    return {
      trs: {
        title1: "基金名称",
        title2: "金额",
        title3: "收益",
        // title4: "收益率"
      },
      lists: [],
      userInfo: {}, //用户信息
      totalRate: 0,
      fundData: {
        holdingIncome: 0,
        fundShare: 0,
        accumIncome: 0
      },
      day7:0
    };
  },
  methods: {
    toDetail(obj){
      this.$router.push({
        name:'FundDetail',
        params:{fundCode:obj.fundCode,fundType:obj.ofundType},
        query:{view:true},
      })
    },
    highestday(){
      highest7().then(res=>{
        if (res.code != 200) {
          this.$message.error("当前请求暂无数据，请稍后再试！");
        } else {
          this.day7=(res.data.annualizedrrinsingleweek).toFixed(2);
          }
      }).catch(err=>{
        console.log(err)
      })
    },
    getpotions() {
      let params = {        
        clientId: this.userInfo.userId,
        bondMark:2
      };     
      findTotalBond(params).then(res => {
        if (res.code != 200) {
          this.$message.error("当前请求失败，请稍后再试！");
        } else {
          this.fundData = res.data;
          sessionStorage.setItem("totalFund", this.fundData.fundShare);
          this.lists = res.data.list;
          if (res.datatotalRate != 0) {
            this.totalRate = res.data.totalRate;
          } else {
            this.totalRate = res.data.totalRate;
          }
        }
      });
    }
  },
  created(){
    this.userInfo = JSON.parse(localStorage.getItem("customerInfo")) || {};   
    this.getpotions();
    this.highestday()
  }
};
</script>
<style lang="less" scoped>

.publicfund {
  padding-bottom: 40px;
  min-height: 600px;
  display: flex;
  .publicfund_cont {
    padding: 40px 40px 0px 40px;
    background: #fff;
    min-height: 1290px;
    width: 930px;
    .title_ {
      height: 60px;
      border-bottom: 1px solid #edf1f7;
      font-size: 20px;
      color: #1f1f1f;
    }
    .price_banner {
      margin-top: 40px;
      height: 102px;
      padding: 16px 19px 0px 19px;
      border-radius: 7px;
      background-image: url("../../../static/img/my/my_fung_bg.png");
      background-size: 100%;
      .ti_price {
        color: #ffffff;
        font-size: 20px;
        font-weight: bold;
      }
      .ti_price_ {
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        margin-left: 14px;
      }
      .price_ {
        display: flex;
        align-items: center;
        margin-top: 36px;
        position: relative;
        .menu {
          width: 202px;
          height: 30px;
          display: flex;
          justify-content: space-between;
          position: absolute;
          right: 18px;
          .getMoney {
            height: 30px;
            width: 96px;
            color: #ffffff;
            text-align: center;
            line-height: 30px;
            background: linear-gradient(288deg, #e0ab6f 0%, #eabf96 100%);
            border-radius: 2px;
          }
          .recharge {
            height: 30px;
            width: 96px;
            color: #ce9b63;
            text-align: center;
            line-height: 30px;
            background: #ffffff;
            border-radius: 2px;
          }
        }
        img {
          width: 26px;
          height: 30px;
          margin-right: 6px;
        }
        .price_num {
          font-size: 30px;
          font-family: Bahnschrift, Bahnschrift-Light;
          font-weight: 300;
          color: #ffffff;
        }
      }
    }
    .tag_cont {
      margin-top: 73px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .tag_ {
        width: 267px;
        height: 200px;
        position: relative;
        border-radius: 32px;
        box-shadow: 0px 4px 12px 0px #edf1f7;
        .tag_img {
          position: absolute;
          left: 102px;
          top: -29px;
          color: #ffff;
          width: 64px;
          height: 58px;
          background: linear-gradient(131deg, #f7d9b7 0%, #eabf96 100%);
          border-radius: 14px;
          box-shadow: 0px 3px 5px -1px rgba(237, 196, 156, 0.58);
          line-height: 58px;
          text-align: center;
          .t {
            font-size: 32px;
          }
        }
        .tag_ti {
          font-size: 20px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: center;
          color: #6b6b6b;
          padding-top: 69px;
          span {
            font-size: 12px;
          }
        }
        .profitac {
          color: #e45247 !important;
        }
        .profit {
          color: #01b834 !important;
        }
        .tag_num {
          margin-top: 34px;
          font-size: 30px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: #1f1f1f;
        }
      }
    }
    .tableline {
      width: 850px;
      height: 1px;
      background: #edf1f7;
      margin-top: 42px;
    }
    .tables_cont {
      width: 850px;
      margin-top: 42px;
      h2 {
        font-size: 18px;
        color: #1f1f1f;
        font-weight: 700;
        margin-bottom: 40px;
      }
    }
  }
}
</style>


