import request from '@/utils/request'

// 公募基金接口

//分页查询-我的-公募基金-持仓明细

export function findTotalBond(data) {
    return request({
      url: "/api/sale/trade/position/findTotalBond",
      method: "POST",
      data
    });
  }

  //惠金宝
  export function highest7(data) {
    return request({
      url: "/api/sale/trade/xjb/highest7",
      method: "POST",
      data
    });
  }
  